/**
 * @module types
 * @redux
 * @description It contains all the redux action types.
 */

/**
 * @const SET_SEA_FCL_JSON
 * @description save FCL json in redux store
 * @type {Redux.ActionType}
 */
export const SET_SEA_FCL_JSON = 'SET_SEA_FCL_JSON';

export const UPDATE_TRANSIT_TIME = "UPDATE_TRANSIT_TIME"

export const UPDATE_PORT_TO_PORT_TRANSIT_TIME = "UPDATE_PORT_TO_PORT_TRANSIT_TIME"

export const SET_FACTORY_FOR_USER="SET_FACTORY_FOR_USER"

export const NEW_FACTOR_FOR_PICKUP="NEW_FACTOR_PICKUP"

export const SET_EXISTING_JSON="SET_EXISTING_JSON"

export const SET_DISCOUNT = "SET_DISCOUNT"
/**
 * @const SET_SEA_LCL_JSON
 * @description save LCL json in redux store
 * @type {Redux.ActionType}
 */
export const SET_SEA_LCL_JSON = 'SET_SEA_LCL_JSON';

/**
 * @const SET_AIR_JSON
 * @description save AIR json in redux store
 * @type {Redux.ActionType}
 */
export const SET_AIR_JSON = 'SET_AIR_JSON';

/**
 * @const SET_PORT_ORIGIN_JSON
 * @description save origin ports json in redux store
 * @type {Redux.ActionType}
 */
export const SET_PORT_ORIGIN_JSON = 'SET_PORT_ORIGIN_JSON';


export const UPDATE_EXISTING_FACTORY_PIC="UPDATE_EXISTING_FACTORY_PIC"

export const UPDATE_EXISTING_FACTORY_DEL="UPDATE_EXISTING_FACTORY_DEL"

export const FINAL_VIEW_AFTER_SUBMIT="FINAL_VIEW_AFTER_SUBMIT"

export const BACK_VIEW_AFTER_SUBMIT="BACK_VIEW_AFTER_SUBMIT"

export const FINAL_VIEW_AFTER_BOOKING="FINAL_VIEW_AFTER_BOOKING"
export const STORE_NEW_ID = 'STORE_NEW_ID';
export const ADD_NEW_PICKUP_FACTORY="ADD_NEW_PICKUP_FACTORY"

export const ADD_NEW_DELIVERY_FACTORY="ADD_NEW_DELIVERY_FACTORY"
/**
 * @const SET_PORT_DESTINATION_JSON
 * @description save destination ports json in redux store
 * @type {Redux.ActionType}
 */
export const SET_PORT_DESTINATION_JSON = 'SET_PORT_DESTINATION_JSON';

/**
 * @const SET_QUERY_PARAMS
 * @description save query params in redux store
 * @type {Redux.ActionType}
 */
export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';

/**
 * @const SET_URL
 * @description save url (it has relevant params) in redux store so it can be used in browser address field
 * @type {Redux.ActionType}
 */
export const SET_URL = 'SET_URL';

/**
 * @const SET_FILTER_BY_CHEAPER
 * @description it sets filter type for result cards on search page
 * @type {Redux.ActionType}
 */
export const SET_FILTER_BY_CHEAPER = 'SET_FILTER_BY_CHEAPER';

/**
 * @const SAVE_SEARCH_FORM_PARAM
 * @description save api request's form params on search page, only when system can't find any rates/ error happens during request
 * @type {Redux.ActionType}
 */
export const SAVE_SEARCH_FORM_PARAM = 'SAVE_SEARCH_FORM_PARAM';

/**
 * @const SET_NEW_RATE_REMAINING_TIME
 * @description save remaining time of new rates by UUID
 * @type {Redux.ActionType}
 */
export const SET_NEW_RATE_REMAINING_TIME = 'SET_NEW_RATE_REMAINING_TIME';

/**
 * @const SET_NEW_RATES_APPEAR
 * @description Do new rate cards present in new rates json by uuid.
 * it is used for showing contact form if no rates appear at all.
 * @type {Redux.ActionType}
 */
export const SET_NEW_RATES_APPEAR = 'SET_NEW_RATES_APPEAR';

/**
 * @const SEARCH_SHOW_NEW_RATE_CONTACT_FORM
 * @description whether to show contact form above result cards on search page
 * @type {Redux.ActionType}
 */
export const SEARCH_SHOW_NEW_RATE_CONTACT_FORM =
  'SEARCH_SHOW_NEW_RATE_CONTACT_FORM';

/**
 * @const UPDATE_ERROR
 * @description it updates errors i.e. error message and loading
 * @type {Redux.ActionType}
 */
export const UPDATE_ERROR = 'UPDATE_ERROR';

/**
 * @const SET_INIT_AUTH
 * @description it saves user and verification in redux store
 * @type {Redux.ActionType}
 */
export const SET_INIT_AUTH = 'SET_INIT_AUTH';

/**
 * @const SET_REFERRAL_DATA
 * @description it saves referral code and referral type in redux store
 * @type {Redux.ActionType}
 */
export const SET_REFERRAL_DATA = 'SET_REFERRAL_DATA';

/**
 * @const SET_UUID_URL_PARAMS_DATA
 * @description it saves req uuid and uuid verification
 * @type {Redux.ActionType}
 */
export const SET_UUID_URL_PARAMS_DATA = 'SET_UUID_URL_PARAMS_DATA';

/**
 * @const UPDATE_POSTAL_ZIP_CODE
 * @description it saves postal zip code array
 * @type {Redux.ActionType}
 */
export const UPDATE_POSTAL_ZIP_CODE = 'UPDATE_POSTAL_ZIP_CODE';

/**
 * @const UPDATE_PICKUP_DELIVERY_ERS_LINE
 * @description it sets whether pickup delivery ers line should be updated
 * @type {Redux.ActionType}
 */
export const UPDATE_PICKUP_DELIVERY_ERS_LINE =
  'UPDATE_PICKUP_DELIVERY_ERS_LINE';

/**
 * @const RE_SORT_RATE_CARD
 * @description re-sort rates cards upon clicking search btn
 * @type {Redux.ActionType}
 */
export const RE_SORT_RATE_CARD = 'RE_SORT_RATE_CARD';

/**
 * @const SET_CODE_SETTINGS
 * @description it saves height of search bar section(i.g. 116) on search page.
 * @type {Redux.ActionType}
 */
export const SET_CODE_SETTINGS = 'SET_CODE_SETTINGS';

/**
 * @const SET_SHOW_RESULT_CARD
 * @description Whether to show [No Result card]{@link NoResult} (it appears when there is NO rate cards in json).
 * @type {Redux.ActionType}
 */
export const SET_SHOW_RESULT_CARD = 'SET_SHOW_RESULT_CARD';

/**
 * @const UPDATE_FCL_COUNTER
 * @description it updates selected FCL types of container numbers.
 * @type {Redux.ActionType}
 */
export const UPDATE_FCL_COUNTER = 'UPDATE_FCL_COUNTER';

export const FORM_BUTTON_CLICK="FORM_BUTTON_CLICK"

/**
 * @const UPDATE_LCL_SHIPMENT
 * @description it updates LCL shipment.
 * @type {Redux.ActionType}
 */
export const UPDATE_LCL_SHIPMENT = 'UPDATE_LCL_SHIPMENT';

/**
 * @const UPDATE_AIR_SHIPMENT
 * @description it updates AIR shipment.
 * @type {Redux.ActionType}
 */
export const UPDATE_AIR_SHIPMENT = 'UPDATE_AIR_SHIPMENT';

/**
 * @const UPDATE_SIDEBAR_ROLE_INCOTERM_SERVICES
 * @description it updates states of sidebar service roles checkboxes on search page.
 * @type {Redux.ActionType}
 */
export const UPDATE_SIDEBAR_ROLE_INCOTERM_SERVICES =
  'UPDATE_SIDEBAR_ROLE_INCOTERM_SERVICES';

/**
 * @const UPDATE_PARENT_SCROLLBAR_POSITION
 * @description It's for parent website scrollbar position and used for margin-top of sailing schedule modal.
 * @type {Redux.ActionType}
 */
export const UPDATE_PARENT_SCROLLBAR_POSITION =
  'UPDATE_PARENT_SCROLLBAR_POSITION';

/**
 * @const UPDATE_RATE_CARD_INFO
 * @description it will contain rate cards state whether cards are expanded or not.
 * @type {Redux.ActionType}
 */
export const UPDATE_RATE_CARD_INFO = 'UPDATE_RATE_CARD_INFO';

/**
 * @const RESET_CONTAINER_TYPE_SLIDER
 * @description it resets sidebar fcl container counters and lcl/air shipment.
 * @type {Redux.ActionType}
 */
export const RESET_CONTAINER_TYPE_SLIDER = 'RESET_CONTAINER_TYPE_SLIDER';

/**
 * @const SET_SEARCH_CUSTOM_MESSAGE_JSON
 * @description it updates [custom message]{@link CustomMessage} on top of results cards on search page.
 * @type {Redux.ActionType}
 */
export const SET_SEARCH_CUSTOM_MESSAGE_JSON = 'SET_SEARCH_CUSTOM_MESSAGE_JSON';

/**
 * @const SET_OVERLAY_FULL_SCREEN
 * @description it sets full screen Overlay.
 * @type {Redux.ActionType}
 */
export const SET_OVERLAY_FULL_SCREEN = 'SET_OVERLAY_FULL_SCREEN';

/**
 * @const SET_TRANSPARENT_OVERLAY_SCREEN
 * @description it sets full screen Overlay.
 * @type {Redux.ActionType}
 */
export const SET_TRANSPARENT_OVERLAY_SCREEN = 'SET_TRANSPARENT_OVERLAY_SCREEN';

/**
 * @const UPDATE_FORM_TAB_PANE
 * @description Whether FCl tab is active or not on Form page.
 * @type {Redux.ActionType}
 */
export const UPDATE_FORM_TAB_PANE = 'UPDATE_FORM_TAB_PANE';

/**
 * @const SHOW_FORM_VALIDATION
 * @description whether to show validation in pickup, delivery form dropdown.
 * @type {Redux.ActionType}
 */
export const SHOW_FORM_VALIDATION = 'SHOW_FORM_VALIDATION';

/**
 * @const UPDATE_FORM_LCL_AIR_TYPE
 * @description it sets lcl or air mode in pallets/boxes/crates tab on form page.
 * @type {Redux.ActionType}
 */
export const UPDATE_FORM_LCL_AIR_TYPE = 'UPDATE_FORM_LCL_AIR_TYPE';

/**
 * @const SHOW_FORM
 * @description Whether to show Form modal i.e Form page.
 * @type {Redux.ActionType}
 */
export const SHOW_FORM = 'SHOW_FORM';

/**
 * @const SET_FORM_CUSTOM_SERVICE
 * @description save custom service section data in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_FORM_CUSTOM_SERVICE = 'SET_FORM_CUSTOM_SERVICE';

/**
 * @const SET_FORM_FCL_SHIPPING
 * @description It updates fcl shipping data in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_FORM_FCL_SHIPPING = 'SET_FORM_FCL_SHIPPING';

/**
 * @const DELETE_FCL_SHIPPING
 * @description It deletes fcl shipping data in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const DELETE_FCL_SHIPPING = 'DELETE_FCL_SHIPPING';

/**
 * @const TOGGLE_LCL_SHIPPING_TYPE
 * @description Whether boxes/pallets or total shipment shipping is selected on LCL mode on form page.
 * @type {Redux.ActionType}
 */
export const TOGGLE_LCL_SHIPPING_TYPE = 'TOGGLE_LCL_SHIPPING_TYPE';

/**
 * @const SET_LCL_TOTAL_SHIPMENT
 * @description It updates lcl or air total shipment on Form page.
 * @type {Redux.ActionType}
 */
export const SET_LCL_TOTAL_SHIPMENT = 'SET_LCL_TOTAL_SHIPMENT';

/**
 * @const SET_LCL_BOX_PALLET
 * @description It updates lcl box/pallet data on Form page.
 * @type {Redux.ActionType}
 */
export const SET_LCL_BOX_PALLET = 'SET_LCL_BOX_PALLET';

/**
 * @const DELETE_LCL_BOX_PALLET
 * @description It deletes lcl box/pallet data on Form page.
 * @type {Redux.ActionType}
 */
export const DELETE_LCL_BOX_PALLET = 'DELETE_LCL_BOX_PALLET';

/**
 * @const SET_FORM_PICKUP_JSON
 * @description It saves pickup ports json in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_FORM_PICKUP_JSON = 'SET_FORM_PICKUP_JSON';

/**
 * @const SET_FORM_PICKUP_EXTRA
 * @description It updates pickup section data in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_FORM_PICKUP_EXTRA = 'SET_FORM_PICKUP_EXTRA';

/**
 * @const SET_PICKUP_EXISTING_FACTORY
 * @description It saves pickup existing factory json in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_PICKUP_EXISTING_FACTORY = 'SET_PICKUP_EXISTING_FACTORY';

export const FINAL_SEARCH_FORM="FINAL_SEARCH_FORM"

export const SET_DELEIVERY_EXISTING_FACTORY="SET_DELEIVERY_EXISTING_FACTORYS"

export const UPDATE_AGENT_DETAILS="UPDATE_AGENT_DETAILS"

export const CHANGE_FINAL_FORM_VIEW_FALSE="CHANGE_FINAL_FORM_VIEW_FALSE"

export const CHANGE_FINAL_FORM_BOOKING_VIEW_FALSE="CHANGE_FINAL_FORM_BOOKING_VIEW_FALSE"

export const CHANGE_BACK_FORM_BOOKING_VIEW_FALSE="CHANGE_BACK_FORM_BOOKING_VIEW_FALSE"

export const FILTER_RATES_BY_CARRIER="FILTER_RATES_BY_CARRIER"
/**
 * @const SET_PICKUP_SELECTED_EXISTING_FACTORY
 * @description It updates pickup selected Existing Factory in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_PICKUP_SELECTED_EXISTING_FACTORY =
  'SET_PICKUP_SELECTED_EXISTING_FACTORY';

/**
 * @const SET_FORM_DELIVERY_JSON
 * @description It saves delivery ports json in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_FORM_DELIVERY_JSON = 'SET_FORM_DELIVERY_JSON';

/**
 * @const SET_FORM_DELIVERY_EXTRA
 * @description It updates delivery section data in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_FORM_DELIVERY_EXTRA = 'SET_FORM_DELIVERY_EXTRA';

/**
 * @const SET_DELIVERY_EXISTING_FACTORY
 * @description It saves delivery existing factory json in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_DELIVERY_EXISTING_FACTORY = 'SET_DELIVERY_EXISTING_FACTORY';

/**
 * @const SET_DELIVERY_SELECTED_EXISTING_FACTORY
 * @description It updates delivery selected Existing Factory in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_DELIVERY_SELECTED_EXISTING_FACTORY =
  'SET_DELIVERY_SELECTED_EXISTING_FACTORY';


export const SET_DELIVERY_SELECTED_EXISTING_FACTORY_NEW =
    'SET_DELIVERY_SELECTED_EXISTING_FACTORY_NEW';
/**
 * @const SET_DELIVERY_ECOMMERCE_DATA
 * @description It updates delivery selected e-commerce mode data in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_DELIVERY_ECOMMERCE_DATA = 'SET_DELIVERY_ECOMMERCE_DATA';

/**
 * @const SET_DELIVERY_AMAZON_DATA
 * @description It updates delivery selected amazon mode data in redux store on Form page.
 * @type {Redux.ActionType}
 */
export const SET_DELIVERY_AMAZON_DATA = 'SET_DELIVERY_AMAZON_DATA';

/**
 * @const FORM_SUBMIT_SUCCESS
 * @description Whether Form is successfully submitted for first time on Form page.
 * @type {Redux.ActionType}
 */
export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS';

/**
 * @const SET_LOAD_SUMMARY_TEXT
 * @description It updates summary text under 'search and book' button on form page.
 * @type {Redux.ActionType}
 */
export const SET_LOAD_SUMMARY_TEXT = 'SET_LOAD_SUMMARY_TEXT';

/**
 * @const SET_PICKUP_DELIVERY_FACTORY
 * @description It sets whether existing factory is selected or not, and selected existing factory array index in dropdown for both pickup and delivery existing/new factory on form page
 * @type {Redux.ActionType}
 */
export const SET_PICKUP_DELIVERY_FACTORY = 'SET_PICKUP_DELIVERY_FACTORY';

/**
 * @const SET_SELECTED_EXISTING_FACTORY_JSON
 * @description set port to selected Existing Factory Json for pickup/delivery on form page.
 * @type {Redux.ActionType}
 */
export const SET_SELECTED_EXISTING_FACTORY_JSON =
  'SET_SELECTED_EXISTING_FACTORY_JSON';

/**
 * @const SET_RECAPTCHA_RES_CODE
 * @description save google reCaptcha response code in redux store
 * @type {Redux.ActionType}
 */
export const SET_RECAPTCHA_RES_CODE = 'SET_RECAPTCHA_RES_CODE';

//api-fcl
//api-lcl
//api-air

// export const SET_PICKUP_REQUIRED = 'SET_API_KEY';

/**
 * @const SET_ORIGINAL_RATE_JSON
 * @description save original/initial rate json in uuid mode
 * @type {Redux.ActionType}
 */
export const SET_ORIGINAL_RATE_JSON = 'SET_ORIGINAL_RATE_JSON';

/**
 * @const SET_DATA_POLLING
 * @description whether to disable capability of data polling (api request) by uuid on landing page
 * @type {Redux.ActionType}
 */
export const SET_DATA_POLLING = 'SET_DATA_POLLING';

/**
 * @const SHOW_SEARCH_BLUR_CARDS
 * @description whether to show blur cards or not on search page
 * @type {Redux.ActionType}
 */
export const SHOW_SEARCH_BLUR_CARDS = 'SHOW_SEARCH_BLUR_CARDS';

/**
 * @const SET_FORM_ZIPCODE_LOADING
 * @description It sets whether pickup/delivery should show loading on rate card on search page,
 * upon submit of Form zipcode submit for new/existing factory.
 * @type {Redux.ActionType}
 */
export const SET_FORM_ZIPCODE_LOADING = 'SET_FORM_ZIPCODE_LOADING';

/**
 * @const SET_SEARCH_BLUR_CARDS_EMAIL
 * @description Save User email on blur cards in redux store, email address of user for blur cards on Search page.
 * @type {Redux.ActionType}
 */
export const SET_SEARCH_BLUR_CARDS_EMAIL = 'SET_SEARCH_BLUR_CARDS_EMAIL';

/**
 * @const SET_DASHBOARD_INIT_JSON
 * @description save initial dashboard json in redux store
 * @type {Redux.ActionType}
 */
export const SET_DASHBOARD_INIT_JSON = 'SET_DASHBOARD_INIT_JSON';

/**
 * @const SET_JWT_TOKEN
 * @description save initial JWT Token from bettyblocks in redux store
 * @type {Redux.ActionType}
 */
export const SET_JWT_TOKEN='SET_JWT_TOKEN';

export const SET_CLIENT_JWT="SET_CLIENT_JWT"

/**
 * @const SET_JWT_TOKEN_TIMING
 * @description save initial JWT Token Timing from bettyblocks in redux store
 * @type {Redux.ActionType}
 */
export const SET_JWT_TOKEN_TIME='SET_JWT_TOKEN_TIME';


export const SET_USER_DETAILS="SET_USER_DETAILS"


export const SET_CARD_SCHEDULE = "SET_CARD_SCHEDULE"


export const SET_SELECTED_SCHEDULE = "SET_SELECTED_SCHEDULE"

export const BOOKING_CUSTOMER_FORM = "BOOKING_CUSTOMER_FORM"

export const SET_COMMODITY_CATEGORY_LIST = "SET_COMMODITY_CATEGORY_LIST"

export const INSURANCE_AUTH_TOKEN = "INSURANCE_AUTH_TOKEN"

export const SET_LOAD_SUMMARY_LCL = 'SET_LOAD_SUMMARY_LCL';

export const PUBLIC_USER_VIEW_AFTER_SUBMIT = "PUBLIC_USER_VIEW_AFTER_SUBMIT"

export const CHANGE_PUBLIC_USER_BOOKING_VIEW_FALSE = "CHANGE_PUBLIC_USER_BOOKING_VIEW_FALSE"
/**
 * @const RESET_LOAD_SUMMARY_LCL
 * @description it resets sidebar lcl container counters and lcl/air shipment.
 * @type {Redux.ActionType}
 */
export const RESET_LOAD_SUMMARY_LCL = "RESET_LOAD_SUMMARY_LCL"

export const SHOW_BACK_PORTAL = "SHOW_BACK_PORTAL"

export const UPDATE_TRANSACTION_TYPE = "UPDATE_TRANSACTION_TYPE"