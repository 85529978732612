import {
  SHOW_FORM,
  SET_FORM_CUSTOM_SERVICE,
  SET_FORM_FCL_SHIPPING,
  SET_FORM_PICKUP_JSON,
  UPDATE_FORM_TAB_PANE,
  SET_FORM_PICKUP_EXTRA,
  TOGGLE_LCL_SHIPPING_TYPE,
  SET_LCL_TOTAL_SHIPMENT,
  SET_LCL_BOX_PALLET,
  DELETE_LCL_BOX_PALLET,
  DELETE_FCL_SHIPPING,
  FORM_SUBMIT_SUCCESS,
  SET_PICKUP_EXISTING_FACTORY,
  SET_FORM_DELIVERY_JSON,
  SET_DELIVERY_EXISTING_FACTORY,
  SET_FORM_DELIVERY_EXTRA,
  SET_PICKUP_SELECTED_EXISTING_FACTORY,
  SET_DELIVERY_SELECTED_EXISTING_FACTORY,
  SET_LOAD_SUMMARY_TEXT,
  SET_LOAD_SUMMARY_LCL,
  RESET_LOAD_SUMMARY_LCL,
  UPDATE_FORM_LCL_AIR_TYPE,
  SET_PICKUP_DELIVERY_FACTORY,
  SHOW_FORM_VALIDATION,
  SET_SELECTED_EXISTING_FACTORY_JSON,
  SET_DELIVERY_ECOMMERCE_DATA,
  SET_DELIVERY_AMAZON_DATA,
  FORM_BUTTON_CLICK,
  SET_EXISTING_JSON,
  SET_DELIVERY_SELECTED_EXISTING_FACTORY_NEW,
  UPDATE_EXISTING_FACTORY_PIC,
  UPDATE_EXISTING_FACTORY_DEL,
  SET_DELEIVERY_EXISTING_FACTORY,
  FINAL_VIEW_AFTER_SUBMIT, ADD_NEW_PICKUP_FACTORY, ADD_NEW_DELIVERY_FACTORY, BACK_VIEW_AFTER_SUBMIT,
  PUBLIC_USER_VIEW_AFTER_SUBMIT,
  CHANGE_PUBLIC_USER_BOOKING_VIEW_FALSE,
  FINAL_VIEW_AFTER_BOOKING,
  STORE_NEW_ID,
  UPDATE_AGENT_DETAILS,
  CHANGE_FINAL_FORM_VIEW_FALSE,
  CHANGE_FINAL_FORM_BOOKING_VIEW_FALSE,
  CHANGE_BACK_FORM_BOOKING_VIEW_FALSE
} from '../actions/types';
import { isDevENV } from '../util/isDevENV';

/**
 * @module form_reducer
 * @redux
 * @description It the redux reducer for form page.
 * @category Form Page
 * @subcategory reducer
 */

/**
 * @type {State}
 * @property {boolean} showFormValidation - whether to show validation in pickup, delivery form dropdown.
 * @property {boolean} isFclTabActive - whether FCL/Containers tab is selected
 * @property {boolean} showForm - Whether to show Form modal i.e Form page
 * @property {boolean} isFormSubmitSuccess - Whether Form is successfully submitted for first time.
 * @property {string} loadSummaryText -  summary text under 'search and book' button.
 *
 * @property {Object} fclShipping -  fcl shipping data
 * @property {Object} fclShipping.id -  one single added load containing added single load info i.e. `{ input, type, isOverweight }`
 * @property {boolean} isLclBoxPalletSelected -  Whether boxes/pallets or total shipment shipping is selected on LCL mode
 *
 * @property {Object} lclAirShipping -  lcl or air mode data in pallets/boxes/crates shipping
 * @property {string} lclAirShipping.type -  Whether lcl or air mode is selected
 * @property {Object} lclAirShipping.boxPallet -  lcl box/pallet data
 * @property {Object} lclAirShipping.boxPallet.id -  one single added load containing added single load info of lcl box/pallet
 * @property {Object} lclAirShipping.totalShipment -  lcl or air total shipment data i.e. `{ quantity, totalVolume, volumeUnit, totalWeight, weightUnit }`
 *
 * @property {Object} pickup -  pickup section data
 * @property {string} pickup.pickupType -  port or factory mode on pickup Origin location type
 * @property {Object} pickup.pickupJson -  pickup ports json
 * @property {string} pickup.id -  selected port id
 * @property {string} pickup.country -  selected port country
 * @property {string} pickup.city -  selected port city
 * @property {string} pickup.date -  pickup date
 * @property {Object} pickup.existingFactoryJson -  pickup existing factory json
 * @property {Object} pickup.selectedExistingFactoryJson -  selected Existing Factory for pickup on factory mode
 * @property {boolean} pickup.isOriginChargeIncluded -  Whether to make checkbox checked by default on pickup section
 *
 * @property {Object} delivery -  delivery section data
 * @property {string} delivery.deliveryType -  port or factory or amazon or ecommerce mode on delivery Origin location type
 * @property {Object} delivery.deliveryJson -  delivery ports json
 * @property {string} delivery.id -  selected port id
 * @property {string} delivery.country -  selected port country
 * @property {string} delivery.city -  selected port city
 * @property {Object} delivery.existingFactoryJson -  delivery existing factory json
 * @property {Object} delivery.selectedExistingFactoryJson -  selected Existing Factory for delivery on factory mode
 *
 * @property {Object} delivery.ecommerceMode -  delivery e-commerce mode data
 * @property {Object} delivery.ecommerceMode.ecommerceJson -  e-commerce json data
 * @property {Object} delivery.ecommerceMode.selectedEcommerceJson -  selected e-commerce factory
 * @property {null | number} [delivery.ecommerceMode.factoryListArrIndex=null] -  array index (starts from zero) of selected e-commerce factory in the list of factories
 *
 * @property {Object} delivery.amazonMode -  delivery amazon mode data
 * @property {undefined | number} delivery.amazonMode.boxSelection -  Amazon radio box selection
 * @property {undefined | number} delivery.amazonMode.palletSelection -  Amazon radio pallet selection
 * @property {Object} delivery.amazonMode.selectedAmazonJson -  selected amazon factory
 * @property {null | number} [delivery.amazonMode.factoryListArrIndex = null] -  array index (starts from zero) of selected amazon factory in the list of factories
 * @property {Object} delivery.amazonMode.amazonJson -  amazon json data
 *
 * @property {boolean} delivery.isDeliveryChargeIncluded -  Whether to make checkbox checked by default on delivery section
 *
 * @property {Object} customService - custom service data
 * @property {boolean} customService.isBrokerageFirstOptionSelected - whether custom brokerage services' radio first option selected i.e. `Yes, I need customs clearance for # commodities`
 * @property {boolean} customService.isBondFirstOptionSelected - whether customs bond's radio first option selected upon select on custom brokerage services' radio first option i.e. `Yes`
 * @property {number} customService.noOfCommodity - custom clearance for how many no. of commodities
 *
 * @property {Object} factoryInfo - pickup/delivery factory info
 *
 * @property {Object} factoryInfo.pickup - pickup factory info
 * @property {null | boolean} [factoryInfo.pickup.isExistingFactorySelected = null] - whether existing factory is selected
 * @property {null | number} [factoryInfo.pickup.existingFactoryListArrIndex=null] - array index (starts from zero) of selected existing factory in the list of factories
 *
 * @property {Object} factoryInfo.pickup.newFactory - if new pickup factory is selected, then it contains it's data
 * @property {string} factoryInfo.pickup.newFactory.address - address of new factory
 * @property {string} factoryInfo.pickup.newFactory.city - city of new factory
 * @property {string} factoryInfo.pickup.newFactory.country - country of new factory
 * @property {string} factoryInfo.pickup.newFactory.postal - postal of new factory
 * @property {string} factoryInfo.pickup.newFactory.name - business name of new factory
 * @property {string} factoryInfo.pickup.newFactory.email - email of new factory
 * @property {Object} factoryInfo.pickup.newFactory.port - port of new factory
 *
 *
 * @property {Object} factoryInfo.delivery - delivery factory info
 * @property {null | boolean} [factoryInfo.delivery.isExistingFactorySelected = null] - whether existing factory is selected
 * @property {null | number} [factoryInfo.delivery.existingFactoryListArrIndex=null] - array index (starts from zero) of selected existing factory in the list of factories
 *
 * @property {Object} factoryInfo.delivery.newFactory - if new delivery factory is selected, then it contains it's data
 * @property {string} factoryInfo.delivery.newFactory.address - address of new factory
 * @property {string} factoryInfo.delivery.newFactory.city - city of new factory
 * @property {string} factoryInfo.delivery.newFactory.country - country of new factory
 * @property {string} factoryInfo.delivery.newFactory.postal - postal of new factory
 * @property {string} factoryInfo.delivery.newFactory.name - business name of new factory
 * @property {string} factoryInfo.delivery.newFactory.email - email of new factory
 * @property {Object} factoryInfo.delivery.newFactory.port - destination city of new factory
 *
 *
 */
const INITIAL_STATE = {
  showFormValidation: false, //make it false
  isFclTabActive: isDevENV() ? true : true, //make it true
  showForm: false,

  // once Form submit is a success then it will remain true for now onwards
  //even if second and further form submit is not a success
  isFormSubmitSuccess: false, //it is for making home/serach page read only

  loadSummaryText: '',
  loadSummaryLCL:{summaryText:'',
  lclLoadSummary:{
    quantity : 1,
    volume : '',
    weight: ''
  },
},
  final_view_after_submit:false,// make it always false this is for testing purpose
  back_view_after_submit:false,
  public_user_view_after_submit: false,
  final_view_after_booking:{
    bookingSuccessful: false,
    bookingId: ''
  },
  store_new_id: '',
  cardSchedule: [],
  selectedSchedule:{},
  AgentInformation:{
    "id": 643552,
    "name":"Prime Freight Logistics",
    "email":"sales@primefreight.com"
  },

  fclShipping: {
    0: {
      input: 1,
      type: "40'",
      isOverweight: false,
    },
  },
  isLclBoxPalletSelected: isDevENV() ? false : true, //make it true
  lclAirShipping: {
    type: 'lcl',
    boxPallet: {
      0: {
        packageType: 'box/crate',
        quantity: 1,

        dimenUnit: 'CM',

        dimenLeftValue: '',
        dimenLeftValueUnit: '',
        showDimenLeftValueUnit: false,

        dimenMidValue: '',
        dimenMidValueUnit: '',
        showDimenMidValueUnit: false,

        dimenRightValue: '',
        dimenRightValueUnit: '',
        showDimenRightValueUnit: false,

        wtUnit: 'KG',

        wtValue: '',
        wtValueUnit: '',
        showWtValueUnit: false,
      },
    },
    totalShipment: {
      quantity: 1,
      totalVolume: '',
      volumeUnit: 'CBM',
      totalWeight: '',
      weightUnit: 'KG',
    },
  },
  pickup: {
    pickupType: '', //make it empty string, //port, factory,
    pickupJson: {},
    id: '',
    country: '',
    city: '',
    date: '',
    existingFactoryJson: {}, //make it empty object
    selectedExistingFactoryJson: {},
    isOriginChargeIncluded:''
  },
  delivery: {
    deliveryType: '', //port, factory, amazon, ecommerce
    deliveryJson: {},
    id: '',
    country: '',
    city: '',
    existingFactoryJson: {},
    selectedExistingFactoryJson: {},
    ecommerceMode: {
      ecommerceJson: {}, //make it {}
      selectedEcommerceJson: {},
      factoryListArrIndex: null,
    },
    amazonMode: {
      boxSelection: undefined,
      palletSelection: undefined,
      selectedAmazonJson: {},
      factoryListArrIndex: null,
      amazonJson: {}, //make it {}
    },
    isDeliveryChargeIncluded:
      process.env.REACT_APP_FORM_INCLUDE_DESTINATION_CHARGE.toLowerCase() ===
      'yes',
  },
  customService: {
    isBrokerageFirstOptionSelected: false,
    isBondFirstOptionSelected: false,
    noOfCommodity:
      process.env.REACT_APP_CUSTOM_SERVICE_USER_DEFAULT_COMMODITY_NO || 1,
  },
  factoryInfo: {
    pickup: {
      isExistingFactorySelected: null, //make it null
      existingFactoryListArrIndex: null,
      //existingMissingPort: '', // when port in a single existing factiry json, is empty
      newFactory: {
        address: '',
        city: '',
        country: '',
        postal: '',
        name: '',
        email: '',
        port: {},
      },
    },
    delivery: {
      isExistingFactorySelected: null, //make it null
      existingFactoryListArrIndex: null, //make it null
      //existingMissingPort: '', // when port in a single existing factiry json, is empty
      newFactory: {
        address: '',
        city: '',
        country: '',
        postal: '',
        name: '',
        email: '',
        port: {},
      },
    },
    formClick:false,
  },
};

const RESET_STATE = {
loadSummaryLCL:{
    summaryText:'',
    lclLoadSummary:{
      quantity : 1,
      volume : '',
      weight: ''
    },
},
lclAirShipping: {
  type: 'lcl',
  boxPallet: {
    0: {
      packageType: 'box/crate',
      quantity: 1,

      dimenUnit: 'CM',

      dimenLeftValue: '',
      dimenLeftValueUnit: '',
      showDimenLeftValueUnit: false,

      dimenMidValue: '',
      dimenMidValueUnit: '',
      showDimenMidValueUnit: false,

      dimenRightValue: '',
      dimenRightValueUnit: '',
      showDimenRightValueUnit: false,

      wtUnit: 'KG',

      wtValue: '',
      wtValueUnit: '',
      showWtValueUnit: false,
    },
  },
}
}

/**
 * @memberof module:form_reducer
 * @description It's the form reducer.
 * @type {Redux.Reducer}
 *
 * @param {Redux.Store} state - The current redux state of the form reducer
 * @param {Redux.Action} action - Redux form action
 *
 * @returns {Redux.Store} The updated redux state
 *
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_FORM_TAB_PANE:
      return { ...state, isFclTabActive: action.payload };
    case UPDATE_FORM_LCL_AIR_TYPE:
      return {
        ...state,
        lclAirShipping: { ...state.lclAirShipping, type: action.payload },
      };
    case TOGGLE_LCL_SHIPPING_TYPE:
      return { ...state, isLclBoxPalletSelected: action.payload };

    case UPDATE_AGENT_DETAILS:
      return {
        ...state,
        AgentInformation:action.payload
      }
    case CHANGE_FINAL_FORM_VIEW_FALSE:
      return {
        ...state,
        final_view_after_submit:action.payload
      }
    case CHANGE_FINAL_FORM_BOOKING_VIEW_FALSE:
      return{
        ...state,
        final_view_after_booking:action.payload
    }
    case CHANGE_BACK_FORM_BOOKING_VIEW_FALSE:
      return{
        ...state,
        back_view_after_booking:action.payload
    }
    case SHOW_FORM:
      return { ...state, showForm: action.payload };
    case FORM_SUBMIT_SUCCESS:
      return { ...state, isFormSubmitSuccess: action.payload };
    case SET_LOAD_SUMMARY_TEXT:
      return { ...state, loadSummaryText: action.payload };
    
    case SET_LOAD_SUMMARY_LCL:
      return { ...state, loadSummaryLCL: action.payload };
    
    case RESET_LOAD_SUMMARY_LCL:
      return {
        ...state,
        lclAirShipping: {
          boxPallet: {
            0: RESET_STATE.lclAirShipping.boxPallet[0], // Keep only the first record
          },
        },
        loadSummaryLCL: { ...state.loadSummaryLCL, ...RESET_STATE.loadSummaryLCL },
      };

    case FINAL_VIEW_AFTER_SUBMIT:
      return {
        ...state,final_view_after_submit:action.payload
      }
    case PUBLIC_USER_VIEW_AFTER_SUBMIT:
      return{
        ...state, public_user_view_after_submit: action.payload
      }
    case CHANGE_PUBLIC_USER_BOOKING_VIEW_FALSE:
      return{
        ...state,
        public_user_view_after_submit: action.payload
      }
    case BACK_VIEW_AFTER_SUBMIT:
      return {
        ...state,back_view_after_submit:action.payload
      }
    case FINAL_VIEW_AFTER_BOOKING:
      return {
        ...state,final_view_after_booking:action.payload
      }
    case STORE_NEW_ID:
      return {
        ...state, store_new_id:action.payload
      }

    case SET_FORM_CUSTOM_SERVICE:
      return {
        ...state,
        customService: { ...state.customService, ...action.payload },
      };
    case SET_FORM_FCL_SHIPPING:
      return {
        ...state,
        fclShipping: {
          ...state.fclShipping,
          [action.payload.id]: {
            ...state.fclShipping[action.payload.id],
            ...action.payload.data,
          },
        },
      };
    case DELETE_FCL_SHIPPING:
      return {
        ...state,
        fclShipping: {
          ...state.fclShipping,
          [action.payload.id]: undefined,
        },
      };

    case SET_FORM_PICKUP_JSON:
      return {
        ...state,
        pickup: { ...state.pickup, pickupJson: action.payload },
      };

    case SET_EXISTING_JSON:
      return {
        ...state,
        pickup:{...state.pickup,existingFactoryJson:action.payload}
      }

    case SET_DELIVERY_SELECTED_EXISTING_FACTORY_NEW:
      return {
        ...state,
        delivery:{...state.delivery,existingFactoryJson:action.payload}
      }

    case SET_PICKUP_EXISTING_FACTORY:
      return {
        ...state,
        pickup :{...state.pickup,selectedExistingFactoryJson:action.payload},
      };

    case ADD_NEW_PICKUP_FACTORY:
      return {
        ...state,
        pickup:{...state.pickup,existingFactoryJson:action.payload}
      }

    case ADD_NEW_DELIVERY_FACTORY:
      return {
        ...state,
        delivery:{...state.delivery, existingFactoryJson:action.payload}
      }
    case SET_DELEIVERY_EXISTING_FACTORY:
      return {
        ...state,
        delivery :{...state.delivery,selectedExistingFactoryJson:action.payload},
      };
    case SET_FORM_PICKUP_EXTRA:
      return {
        ...state,
        pickup: { ...state.pickup, ...action.payload },
      };

    case SET_PICKUP_SELECTED_EXISTING_FACTORY:
      return {
        ...state,
        pickup: { ...state.pickup, ...action.payload },
      };
    case SET_DELIVERY_SELECTED_EXISTING_FACTORY:
      return {
        ...state,
        delivery: { ...state.delivery, ...action.payload },
      };

    case SET_FORM_DELIVERY_JSON:
      return {
        ...state,
        delivery: { ...state.delivery, deliveryJson: action.payload },
      };
    case SET_DELIVERY_EXISTING_FACTORY:
      return {
        ...state,
        delivery: { ...state.delivery, existingFactoryJson: action.payload },
      };

    case UPDATE_EXISTING_FACTORY_PIC:
      const { index, updatedValue } = action.payload;
      const updatedCollection = [...state.pickup.existingFactoryJson];
      updatedCollection[index] = updatedValue;
      return {
        ...state,
        pickup:{
          ...state.pickup,
          existingFactoryJson:updatedCollection
        }
      }

    case UPDATE_EXISTING_FACTORY_DEL:
      const { index: delIndex, updatedValue: delUpdatedValue } = action.payload;
      const delUpdatedCollection = [...state.delivery.existingFactoryJson];
      delUpdatedCollection[delIndex] = delUpdatedValue;
      return {
        ...state,
        delivery: {
          ...state.delivery,
          existingFactoryJson: delUpdatedCollection
        }
      }


    case SET_DELIVERY_ECOMMERCE_DATA:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          ecommerceMode: {
            ...state.delivery.ecommerceMode,
            ...action.payload,
          },
        },
      };
    case SET_DELIVERY_AMAZON_DATA:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          amazonMode: {
            ...state.delivery.amazonMode,
            ...action.payload,
          },
        },
      };
    case SET_FORM_DELIVERY_EXTRA:
      return {
        ...state,
        delivery: { ...state.delivery, ...action.payload },
      };
    case SET_PICKUP_DELIVERY_FACTORY:
      return {
        ...state,
        factoryInfo: {
          ...state.factoryInfo,
          [action.payload.type]: {
            ...state.factoryInfo[action.payload.type],
            ...action.payload.data,
          },
        },
      };

    // set port to selectedExistingFactoryJson for pickup/delivery
    case SET_SELECTED_EXISTING_FACTORY_JSON:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          selectedExistingFactoryJson: {
            ...(state[action.payload.type].selectedExistingFactoryJson || {}),
            ...action.payload.data,
          },
        },
      };

    case SET_LCL_TOTAL_SHIPMENT:
      return {
        ...state,
        lclAirShipping: {
          ...state.lclAirShipping,
          totalShipment: {
            ...state.lclAirShipping.totalShipment,
            ...action.payload,
          },
        },
      };
    case SET_LCL_BOX_PALLET:
      return {
        ...state,
        lclAirShipping: {
          ...state.lclAirShipping,
          boxPallet: {
            ...state.lclAirShipping.boxPallet,
            [action.payload.id]: {
              ...state.lclAirShipping.boxPallet[action.payload.id],
              ...action.payload.data,
            },
          },
        },
      };
    case DELETE_LCL_BOX_PALLET:
      const tempObj = state.lclAirShipping.boxPallet;
      const newBoxPallet = Object.keys(tempObj).reduce(function (obj, key) {
        if (Number(key) !== action.payload.id) {
          obj[key] = tempObj[key];
        }
        return obj;
      }, {});

      return {
        ...state,
        lclAirShipping: {
          ...state.lclAirShipping,
          boxPallet: newBoxPallet,
        },
      };

    case SHOW_FORM_VALIDATION:
      return {
        ...state,
        showFormValidation: action.payload,
      };

    case FORM_BUTTON_CLICK:
      return {
        ...state,
        formClick:action.payload
      }
    default:
      return state;
  }
};
